* {
  box-sizing:border-box;
  font-family: sans-serif;
}

body {
  margin: 0;
  min-height: 100vh;
}

.App {
  height: 100vh;
  background-color: #fdfeff;
  display: flex;
  flex-direction: column;
}

.App.signedin {
  background-color: #f3f4f6;
}

a:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
  color: blue;
}

button:disabled {
  display: none;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fdfeff;
  padding: 25px;
}

.navbar.small {
  padding: 8px;
}

.landingpage-links-container {
  display: flex;
  align-items: center;
}

.landingpage-links-container a {
  color: #2d3543;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}

.landingpage-links-container a:hover {
  text-decoration: none;
}

.navbar .logo {
  width: 300px;
  margin-bottom: -5px;
}

.navbar.small .logo {
  width: 250px;
}

.account-options-container {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.credit-tracker {
  margin-right: 20px;
  background-color: #2d3543;
  padding: 12px 20px;
  color: #fdfeff;
  border-radius: 6px;
}

.credit-tracker span {
  color: #fcbf24;
}

.login-link-container {
  margin-right: 10px;
}

.signup-link-container .signup-btn {
  background-color: #4c4fee;
  border-radius: 4px;
  border: 1px solid transparent;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Gilroy,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 700;
  line-height: 18px;
  font-size: .875rem;
  padding: 12px 20px;
  text-align: center;
  transition: all .3s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  box-shadow: 0 3px 8px 0 rgb(44 54 67 / 20%);
  letter-spacing: .25px;
}

.signup-link-container .signup-btn:hover {
  background-color: #1d21ea;
}

.login-link-container .login-btn,
.logout-btn {
  box-sizing: border-box;
  border: 2px solid #2c3543;
  background: transparent;
  transition: all .3s ease-in-out;
  color: #2c3543;
  font-weight: 700;
  letter-spacing: .25px;
  padding-top: 12px;
  box-shadow: none;
}

.login-link-container .login-btn:hover,
.logout-btn:hover {
  background-color: #2c3543;
  color: #fff;
}

.loading-gif {
  display: block;
  width: 40px;
  margin: 20px auto 0 auto;
}

.table-container {
  margin-top: 80px;
}

table {
  border-collapse: collapse;
  margin: 0 auto;
  font-size: 0.9em;
  min-width: 400px;
  width: 85vw;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

table thead tr {
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

table th,
table td {
  padding: 12px 15px;
}

th {
  position: relative;
}

th svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
  background-color: #fff;
  height: 80px;
}

table tbody tr:hover,
table tbody tr:nth-of-type(even):hover {
  background-color: rgba(0,0,0,0.1);
}

table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

.seo-btn {
  width: 200px
}

.pagination-controlls-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 85vw;
  margin: 0 auto 0 auto;
  padding-right: 20px;
  background-color: #fdfeff;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
  border-radius: 0 0 15px 15px;
}

.pagination-controlls-container button {
  padding: 5px;
  height: 30px;
  width: 80px;
  margin-left: 10px
}

.page-count-text {
  margin-right: 18px;
}

select {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-block;
  height: 28px;
  padding: 0.2em 3.5em 0.2em 1em;
  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(0.65em + 2px),
    calc(100% - 15px) calc(0.65em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1em;
  background-repeat: no-repeat;
}

select option {
  color: inherit;
  background-color: #320a28;
}
select:focus {
  outline: none;
}
select::-ms-expand {
  display: none;
}

.company-information-form {
  display: flex;
  justify-content: center;
  padding: 14px;
  align-items: center;
  margin-bottom: 20px;
}

::placeholder {
  font-size: 13px;
  font-weight: 500;
  color: #a1aab2;
}

input {
  background-color: #edf2f7;
  border-radius: 6px;
  padding: 5px 4px 5px 16px;
  border: none;
  outline: 0;
  width: 318px;
  height: 40px;
  margin-right: 20px;
}

button {
  padding: 12px 20px;
  color: #fdfeff;
  border-radius: 6px;
  border: 0;
  outline: none;
  cursor: pointer;
}

.csv-download-btn,
.save-btn {
  background-color: #fdfeff;
  display: flex;
  align-items: center;
  border-radius: 6px;
  width: 80px;
  height: 40px;
  padding: 0;
  margin-right: 10px;
  color: #2e3543;
  border: 1px solid #dadce0;
}

.settings-container {
  width: 180px;
}

.settings-btn {
  width: 40px;
  height: 40px;
}

.settings-btn svg {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

.csv-download-btn:hover,
.save-btn:hover,
.settings-btn {
  text-decoration: none;
  background-color: #f9fafb;
}

.download-icon,
.save-icon {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  margin-left: 10px;
  color: #2e3543;
}

/* -- Settings Modal -- */

.settings-modal-parent {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.settings-modal {
  position: absolute;
  width: 400px;
  height: 470px;
  overflow: auto;
  margin: auto;
  top: 0; left: 0; bottom: 0; right: 0;
  border-radius: 6px;
  background-color: #fdfeff;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}

.close-btn {
  width: 50px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
  right: 0;
  position: absolute;
  margin: 16px;
}

.close-btn svg {
  width: 30px;
  height: 30px;
}

.settings-modal h1 {
  margin-top: 20px;
}

.settings-modal h1,
.settings-modal h4 {
  margin-left: 40px;
}

.column-checkbox-container label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
  margin: 0 auto;
}

.column-checkbox-container input {
  width: 16px;
}

/* -- Landing Page -- */

.landing-page {
  color: #2c3543;
}

.landing-page .hero-text {
  width: 1000px;
  margin: 80px auto 40px auto;
  text-align: center;
  font-size: 78px;
}

.landing-page .sub-hero-text {
  text-align: center;
  font-size: 25px;
  line-height: 33px;
  font-weight: 500;
  max-width: 800px;
  margin: 0 auto;
}

.hero-cta-container {
  width: 500px;
  margin: 80px auto;
  display: flex;
  justify-content: center;
}

.hero-cta-container .login-btn,
.hero-cta-container .signup-btn,
.footer-cta-container .signup-btn {
  width: 210px;
  height: 60px;
}

.hero-cta-container input {
  height: 60px;
  font-size: 18px;
  color: #2d3543;
  margin-right: 0;
  border-radius: 6px 0 0 6px;
  box-shadow: 0 3px 8px 0 rgb(44 54 67 / 20%);
}

.hero-cta-container input::placeholder {
  color: #6b7280;
  font-size: 18px;
}

.hero-cta-container .signup-btn {
  outline: none;
  border-radius: 0 6px 6px 0;
}

.people-finder-img-container {
  width: 1000px;
  height: 468px;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
}

.people-finder-img {
  width: 100%;
}

.find-leads-container {
  margin: 120px;
}

.find-leads-text-container {
  text-align: center;
  width: 720px;
  margin: 40px auto;
}

.find-leads-container h6 {
  font-size: 16px;
  text-transform: uppercase;
  color: #4e4fee;
  margin: 0;
}

.find-leads-container h4 {
  font-size: 36px;
  margin: 10px;
}

.find-leads-container p {
  font-size: 20px;
  color: #6b7280;
}

.datapoints-parent-container {
  display: flex;
  justify-content: space-between;
  width: 900px;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 3px 8px 0 rgb(44 54 67 / 20%);
  margin-bottom: 40px;
  background-color: #f3f4f6;
  text-align: center;
}

.datapoint-container {
  width: 298px;
  height: 128px;
  background-color: #fdfeff;
  padding: 20px;
}

.datapoint-container h2 {
  font-size: 48px;
  margin: 0;
  margin-bottom: 8px;
  color: #4d5fee;
}

.datapoints-parent-container .datapoint-container p {
  font-size: 18px;
  margin: 0;
}

.footer-cta-container {
  display: flex;
  height: 272px;
  background-color: #eef2ff;
  justify-content: center;
  align-items: center;
}

.footer-cta-text {
  font-size: 36px;
  width: 500px;
  margin-right: 100px;
}

.footer-cta-text span {
  color: #4d5fee
}

/* Pricing Page */

.pricing-page {
  text-align: center;
}

.pricing-page .hero-text {
  font-size: 48px;
  margin-top: 40px;
  margin-bottom: 0;
}

.pricing-page .sub-hero-text {
  font-size: 20px;
  color: #6b7280;
  margin-top: 20px;
  margin-bottom: 30px;
}

.billing-options-container {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 40px;
  box-shadow: 0 3px 8px 0 rgb(44 54 67 / 20%);
}

.billing-options-container button {
  width: 160px;
  font-weight: 600;
  color: #2d3543;
  background-color: #f3f4f6;
}

.billing-options-container button.selected {
  border-radius: 6px;
  color: #2d3543;
  background-color: #fff;
}

.billing-options-container .monthly-billing-btn {
  border-radius: 6px 0 0 6px;
}

.billing-options-container .yearly-billing-btn {
  border-radius: 0 6px 6px 0;
}

.billing-cards-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.billing-card {
  text-align: left;
  margin-right: 20px;
  width: 300px;
  height: 600px;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgb(44 54 67 / 20%);
}

.billing-card.trial .card-header h2 span {
  display: none;
}

.card-header {
  border-bottom: 1px solid #e5e7eb;
}

.card-header,
.card-description {
  padding: 25px;
}

.card-header p {
  height: 40px;
  font-size: 14px;
  color: #6b7280;
}

.card-header h2 {
  font-size: 36px;
}

.card-header h2 span {
  font-size: 16px;
  color: #6b7280;
}

.card-header h4 {
  margin: 0;
  font-size: 16px;
}

.card-header button {
  width: 100%;
}

.card-description h6 {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 500;
  margin-bottom: 25px;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.feature p {
  margin: 0;
  font-size: 14px;
  color: #6b7280;
}

.feature svg {
  margin-right: 10px;
}

/* -- Login -- */

.login-container,
.signup-container {
  background-color: #fff;
  color: #2c3543;
  border-radius: 8px;
  box-shadow: 0 12px 48px rgb(26 33 52 / 11%);
  max-width: 460px;
  min-width: 320px;
  padding: 32px;
  width: 100%;
  margin: 120px auto 0 auto;
}

.login-container .title-text,
.signup-container .title-text {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.google-icon {
  align-items: center;
  display: flex;
  line-height: 32px;
  margin-right: 12px;
}

.login-container input,
.signup-container input {
  border: 1px solid #e7e9f5;
  border-radius: 4px;
  display: flex;
  height: 48px;
  width: 100%;
  flex: 1;
  padding: 12px 12px;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 20px;
  background-color: #fff;
  outline: none;
}

.login-container input::placeholder,
.signup-container input::placeholder {
  color: #4d536e;
  font-size: 14px;
  line-height: 21px;
}

.login-container button,
.signup-container button {
  display: block;
  align-items: center;
  border-radius: 6px;
  display: flex;
  height: 48px;
  width: 100%;
  margin-top: 20px;
  justify-content: center;
  padding: 8px 16px;
  position: relative;
  font-family: Gilroy,Helvetica Neue,Helvetica,Arial,sans-serif;
  font-weight: 700;
  line-height: 18px;
  font-size: .875rem;
}

.login-container .login-btn:hover,
.signup-container .signup-btn:hover,
.card-header button:hover {
  background-color: #1d21ea;
}

.login-container .login-btn,
.signup-container .signup-btn,
.card-header button {
  background-color: #4c4fee;
}

.login-with-google-btn,
.signup-with-google-btn {
  background: #fff;
  border: 1px solid #e7e9f5;
  border-radius: 4px;
  color: #4d536e;
  outline: none;
}

.login-with-google-btn:hover,
.signup-with-google-btn:hover {
  border: 1px solid #c6cbde;
  background-color: #f9faff;
}

.login-footer-text,
.signup-footer-text {
  color: #6D758D;
  font-size: 12px;
  line-height: 18px;
}

/* -- Home -- */

.home-container {
  color: #2d3543;
  width: 600px;
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.home-container h1 {
  margin: 0;
  font-size: 48px;
}

.options {
  display: flex;
  justify-content: space-evenly;
}

.option-container {
  color: #2d3543;;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 12px rgb(0 0 0 / 15%);
}

.option-container:hover {
  text-decoration: none;
}

.option-container svg {
  display: block;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.table-options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85vw;
  margin: 0 auto;
  margin-bottom: -10px;
  padding-top: 5px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 10px;
  border: 1px solid #dadce0;
  border-radius: 6px;
  background-color: #fdfeff;
  box-shadow: 0 0 20px rgb(0 0 0 / 15%);
}

.table-options-container h2 {
  margin-right: 10px;
}

.search-container,
.save-options-container {
  display: flex;
  align-items: center;
}

.settings-btn {
  padding: 0;
}

.company-information-form button,
.settings-btn {
  background-color: #fdfeff;
  color: #2d3543;
  border: 1px solid #dadce0;
}

.company-information-form button:hover,
.settings-btn:hover {
  background-color: #f9fafb;
}

.company-information-form input,
.table-options-container input {
  background-color: #fdfeff;
  color: #2d3543;
  border: 1px solid #dadce0;
}

.company-information-form input::placeholder {
  color: #5f6367;
}

.app-title {
  text-align: center;
  color: #2d3543;
  font-size: 48px;
  margin-bottom: 10px;
}

/* -- Business Finder -- */

.business-finder {
  padding-bottom: 40px;
  background-color: #fdfeff;
  height: 100%;
}

table thead tr {
  background-color: #2e3543;
}

table button,
.pagination-controlls-container button {
  background-color: #fdfeff;
  color: #2d3543;
  border: 1px solid #dadce0;
}

table button:hover,
.pagination-controlls-container button:hover {
  background-color: #f9fafb;
}

.save-icon,
.table-options-container h2 {
  color: #2e3543;
}

/* -- People Finder -- */

.people-finder {
  padding-bottom: 40px;
  background-color: #fdfeff;
  height: 100%;
}

/* -- Footer -- */

.footer {
  padding: 40px;
  background-color: #fdfeff;
  margin-top: auto;
}
